import { useState, useEffect } from 'react';
import { isBirthdayToday } from '../config/birthday';

export const useBirthdayCheck = () => {
  const [isBirthday, setIsBirthday] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const checkBirthday = () => {
      try {
        const result = isBirthdayToday();
        setIsBirthday(result);
      } catch (error) {
        console.error('Error checking birthday:', error);
        setIsBirthday(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkBirthday();

    // Check again at midnight
    const now = new Date();
    const tomorrow = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
    const timeUntilMidnight = tomorrow.getTime() - now.getTime();

    const midnightTimer = setTimeout(checkBirthday, timeUntilMidnight);

    return () => clearTimeout(midnightTimer);
  }, []);

  return { isBirthday, isLoading };
}; 