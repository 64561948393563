import { useMemo } from 'react';

export const useFeatures = () => {
  const features = useMemo(() => ({
    isVisitCounterEnabled: import.meta.env.VITE_ENABLE_VISIT_COUNTER === 'true',
    isMusicSectionEnabled: import.meta.env.VITE_ENABLE_MUSIC_SECTION === 'true',
    isVideoSectionEnabled: import.meta.env.VITE_ENABLE_VIDEO_SECTION === 'true',
    isTourDatesEnabled: import.meta.env.VITE_ENABLE_TOUR_DATES === 'true',
    isSocialLinksEnabled: import.meta.env.VITE_ENABLE_SOCIAL_LINKS === 'true',
    isNewsletterEnabled: import.meta.env.VITE_ENABLE_NEWSLETTER === 'true',
    isDarkModeEnabled: import.meta.env.VITE_ENABLE_DARK_MODE === 'true',
    isTopCountriesEnabled: import.meta.env.VITE_SHOW_TOP_COUNTRIES === 'true'
  }), []);

  return features;
}; 