import { collection, serverTimestamp, doc, getDoc, increment, setDoc, getDocs, query, orderBy, Timestamp } from 'firebase/firestore';
import { db } from '../config/firebase';

interface VisitStats {
  totalVisits: number;
  uniqueVisitors: number;
  countryStats: { [key: string]: number };
  lastUpdated: Date;
  lastVerified: Date | Timestamp;
  verifiedTotals?: {
    total: number;
    byCountry: { [key: string]: number };
    timestamp: Date;
  };
}

// Constants
const MAX_RETRIES = 5;
const RETRY_DELAY = 1000;
const STATS_COLLECTION = 'statistics';
const STATS_DOCUMENT = 'visitor_stats';

// Helper function to wait between retries
const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

// Helper function to handle retries
async function withRetry<T>(operation: () => Promise<T>, retries = MAX_RETRIES): Promise<T> {
  try {
    return await operation();
  } catch (error: any) {
    if (retries > 0 && (error.code === 'failed-precondition' || error.code === 'aborted')) {
      await wait(RETRY_DELAY);
      return withRetry(operation, retries - 1);
    }
    throw error;
  }
}

export const trackVisit = async (): Promise<void> => {
  try {
    const statsRef = doc(db, STATS_COLLECTION, STATS_DOCUMENT);
  const statsDoc = await getDoc(statsRef);
  
  if (!statsDoc.exists()) {
    await setDoc(statsRef, {
      totalVisits: 1,
      uniqueVisitors: 1,
      lastUpdated: serverTimestamp()
    });
    } else {
  await setDoc(statsRef, {
    totalVisits: increment(1),
    lastUpdated: serverTimestamp()
  }, { merge: true });
    }
  } catch (error) {
    console.error('Error tracking visit:', error);
  }
};

export async function backupCurrentStats(): Promise<void> {
  try {
    await withRetry(async () => {
      const statsRef = doc(db, STATS_COLLECTION, STATS_DOCUMENT);
      const statsDoc = await getDoc(statsRef);
      
      if (statsDoc.exists()) {
        const backupRef = doc(db, STATS_COLLECTION, `backup_${Date.now()}`);
        await setDoc(backupRef, statsDoc.data());
      }
    });
  } catch (error) {
    console.error('Failed to backup statistics:', error);
    throw error;
  }
}

export async function restoreFromBackup(backupId: string): Promise<void> {
  try {
    await withRetry(async () => {
      const backupRef = doc(db, STATS_COLLECTION, backupId);
      const backupDoc = await getDoc(backupRef);
      
      if (!backupDoc.exists()) {
        throw new Error('Backup not found');
      }
      
      const statsRef = doc(db, STATS_COLLECTION, STATS_DOCUMENT);
      await setDoc(statsRef, backupDoc.data());
    });
  } catch (error) {
    console.error('Failed to restore from backup:', error);
    throw error;
  }
}

export async function rebuildStatistics(): Promise<void> {
  try {
    await withRetry(async () => {
      const statsRef = doc(db, STATS_COLLECTION, STATS_DOCUMENT);
      const visitorsRef = collection(db, 'visitors');
      
      // Create backup before rebuild
      await backupCurrentStats();

      // Get all visitor records
      const visitorSnapshot = await getDocs(query(visitorsRef, orderBy('timestamp', 'desc')));

      const countryStats: { [key: string]: number } = {};
      const uniqueIps = new Set<string>();
      
      visitorSnapshot.forEach((doc) => {
        const visit = doc.data();
        const country = visit.geoData?.country_name || 'Unknown';
        countryStats[country] = (countryStats[country] || 0) + 1;
        if (visit.ip) uniqueIps.add(visit.ip);
      });

      const now = new Date();
      const newStats: VisitStats = {
        totalVisits: visitorSnapshot.size,
        uniqueVisitors: uniqueIps.size,
        countryStats,
        lastUpdated: now,
        lastVerified: Timestamp.fromDate(now),
        verifiedTotals: {
          total: visitorSnapshot.size,
          byCountry: countryStats,
          timestamp: now
        }
      };

      await setDoc(statsRef, newStats);
      console.log('Statistics rebuilt successfully:', newStats);
    });
  } catch (error) {
    console.error('Failed to rebuild statistics:', error);
    throw error;
  }
} 