import { useState, useEffect } from 'react';
import { doc, onSnapshot, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../config/firebase';

interface CountryStats {
  [country: string]: number;
}

interface VisitorStats {
  totalVisits: number;
  uniqueVisitors: number;
  countryStats: CountryStats;
  lastUpdated: Date;
}

const DEFAULT_STATS: VisitorStats = {
  totalVisits: 0,
  uniqueVisitors: 0,
  countryStats: {},
  lastUpdated: new Date()
};

export const useVisitCounter = () => {
  const [stats, setStats] = useState<VisitorStats>(DEFAULT_STATS);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let retryCount = 0;
    const maxRetries = 3;
    const retryDelay = 1000; // 1 second
    let unsubscribeListener: (() => void) | undefined;

    const setupListener = async () => {
      try {
        const statsRef = doc(db, 'statistics', 'visitor_stats');
        
        // Initialize document if it doesn't exist
        const initializeStats = async () => {
          try {
            await setDoc(statsRef, {
              totalVisits: 0,
              uniqueVisitors: 0,
              countryStats: {},
              lastUpdated: serverTimestamp()
            }, { merge: true });
          } catch (error) {
            console.error('Error initializing stats:', error);
            throw error;
          }
        };

        unsubscribeListener = onSnapshot(
          statsRef,
          {
            next: (doc) => {
              if (doc.exists()) {
                const data = doc.data();
                setStats({
                  totalVisits: data.totalVisits || 0,
                  uniqueVisitors: data.uniqueVisitors || 0,
                  countryStats: data.countryStats || {},
                  lastUpdated: data.lastUpdated?.toDate() || new Date()
                });
                setError(null);
              } else {
                console.log('Initializing statistics document...');
                initializeStats().catch(error => {
                  console.error('Failed to initialize statistics:', error);
                  setError('Failed to initialize statistics');
                });
              }
              setLoading(false);
            },
            error: (err) => {
              console.error('Error fetching visitor stats:', err);
              setError('Failed to load visitor statistics');
              setLoading(false);

              // Retry on error
              if (retryCount < maxRetries) {
                retryCount++;
                console.log(`Retrying... Attempt ${retryCount} of ${maxRetries}`);
                setTimeout(setupListener, retryDelay * Math.pow(2, retryCount - 1));
              }
            }
          }
        );
      } catch (err) {
        console.error('Error setting up visitor stats listener:', err);
        setError('Failed to initialize visitor statistics');
        setLoading(false);

        // Retry on error
        if (retryCount < maxRetries) {
          retryCount++;
          console.log(`Retrying... Attempt ${retryCount} of ${maxRetries}`);
          setTimeout(setupListener, retryDelay * Math.pow(2, retryCount - 1));
        }
      }
    };

    setupListener();

    return () => {
      if (unsubscribeListener) {
        unsubscribeListener();
      }
    };
  }, []);

  // Get top 5 countries by visits
  const topCountries = Object.entries(stats.countryStats)
    .sort(([, a], [, b]) => b - a)
    .slice(0, 5)
    .reduce((acc, [country, count]) => {
      acc[country] = count;
      return acc;
    }, {} as CountryStats);

  return {
    totalVisits: stats.totalVisits,
    uniqueVisitors: stats.uniqueVisitors,
    countryStats: topCountries,
    lastUpdated: stats.lastUpdated,
    loading,
    error
  };
}; 