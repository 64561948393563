import React from 'react';
import { type ThemeType } from '../../config/themes';

interface ThemeSwitcherProps {
  currentTheme: ThemeType;
  onSwitch: () => void;
}

export const ThemeSwitcher: React.FC<ThemeSwitcherProps> = ({ currentTheme, onSwitch }) => {
  const getIcon = () => {
    switch (currentTheme) {
      case 'dark':
        return '🌙'; // Moon for dark theme
      case 'light':
        return '☀️'; // Sun for light theme
      case 'cosmic':
        return '✨'; // Stars for cosmic theme
      default:
        return '🎨';
    }
  };

  return (
    <button
      onClick={onSwitch}
      className="fixed bottom-6 right-6 p-4 rounded-full backdrop-blur-sm bg-white/10 hover:bg-white/20 transition-all duration-300 shadow-lg group"
      title={`Current theme: ${currentTheme}`}
    >
      <span className="text-2xl transform group-hover:scale-110 inline-block transition-transform duration-200">
        {getIcon()}
      </span>
    </button>
  );
}; 