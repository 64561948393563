import { useState, useCallback } from 'react';
import { type ThemeType, themes } from '../config/themes';

export const useTheme = (initialTheme: ThemeType = 'dark') => {
  const [currentTheme, setCurrentTheme] = useState<ThemeType>(initialTheme);

  const cycleTheme = useCallback(() => {
    setCurrentTheme(current => {
      switch (current) {
        case 'dark': return 'light';
        case 'light': return 'cosmic';
        case 'cosmic': return 'dark';
        default: return 'dark';
      }
    });
  }, []);

  return {
    theme: themes[currentTheme],
    currentThemeName: currentTheme,
    cycleTheme,
  };
}; 