import { collection, addDoc, serverTimestamp, doc, getDoc, increment, setDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { UAParser } from 'ua-parser-js';
import { getIpGeoLocation } from './geoLocation';

// Define NetworkInformation type
interface NetworkInformation {
  type?: string;
  effectiveType?: string;
  downlink?: number;
  rtt?: number;
  saveData?: boolean;
}

// Extend Navigator interface to include non-standard properties
interface ExtendedNavigator extends Navigator {
  deviceMemory?: number;
  connection?: NetworkInformation;
  mozConnection?: NetworkInformation;
  webkitConnection?: NetworkInformation;
}

interface DeviceData {
  // Device Identification
  fingerprint: string;
  userAgent: string;
  deviceType: 'mobile' | 'tablet' | 'desktop' | 'other';
  
  // IP and Location Info
  ip: string;
  geoData: {
    city: string;
    region: string;
    country: string;
    country_name: string;
    latitude: number;
    longitude: number;
    elevation: number;
    timezone: string;
    org: string;
    postal: string;
    geoSource: string;
  };
  geoData2: {
    city: string;
    region: string;
    country: string;
    country_name: string;
    latitude: number;
    longitude: number;
    elevation: number;
    timezone: string;
    org: string;
    postal: string;
    geoSource: string;
  };
  
  // System Info
  os: {
    name: string;
    version: string;
    platform: string;
  };
  browser: {
    name: string;
    version: string;
    engine: string;
    engineVersion: string;
  };
  
  // Device Specs
  screen: {
    width: number;
    height: number;
    colorDepth: number;
    pixelRatio: number;
    orientation: string;
  };
  
  // Hardware Info
  cpu: {
    architecture: string;
    cores: number | null;
  };
  memory: {
    deviceMemory: number | null;
  };
  
  // Network & Performance
  network: {
    type: string | null;
    effectiveType: string | null;
    downlink: number | null;
    rtt: number | null;
    saveData: boolean | null;
  };
  
  // Additional Info
    language: string;
  languages: readonly string[];
  timeZone: string;
  timestamp: any;
  
  // Mobile Specific
  mobile: {
    vendor: string | null;
    model: string | null;
    type: string | null;
  };
}

const generateDeviceFingerprint = (): string => {
  const extendedNavigator = navigator as ExtendedNavigator;
  const components = [
    navigator.userAgent,
    screen.width,
    screen.height,
    screen.colorDepth,
    new Date().getTimezoneOffset(),
    navigator.language,
    navigator.languages?.join(','),
    navigator.hardwareConcurrency,
    (extendedNavigator as any).deviceMemory,
  ];
  
  // Simple hash function
  let hash = 0;
  const str = components.join('|');
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = ((hash << 5) - hash) + char;
    hash = hash & hash;
  }
  return hash.toString(36);
};

const getNetworkInformation = (): DeviceData['network'] => {
  const extendedNavigator = navigator as ExtendedNavigator;
  const connection = extendedNavigator.connection || 
                    extendedNavigator.mozConnection || 
                    extendedNavigator.webkitConnection;
  
  if (connection) {
    return {
      type: connection.type || null,
      effectiveType: connection.effectiveType || null,
      downlink: connection.downlink || null,
      rtt: connection.rtt || null,
      saveData: connection.saveData || null
    };
  }

  return {
    type: null,
    effectiveType: null,
    downlink: null,
    rtt: null,
    saveData: null
  };
};

export const collectDeviceData = async (): Promise<void> => {
  try {
    // Get IP and geolocation data
    const geoData = await getIpGeoLocation();
    
    // Create parser instance and parse user agent
    const parser = new UAParser();
    const result = parser.getResult();
    const extendedNavigator = navigator as ExtendedNavigator;
    
    const deviceData: DeviceData = {
      fingerprint: generateDeviceFingerprint(),
      userAgent: navigator.userAgent,
      deviceType: result.device.type as DeviceData['deviceType'] || 'other',
      
      // Add IP and location data with null checks
      ip: geoData.ip || 'unknown',
      geoData: {
        city: geoData.geoData?.city || 'Unknown',
        region: geoData.geoData?.region || 'Unknown',
        country: geoData.geoData?.country || 'UN',
        country_name: geoData.geoData?.country_name || 'Unknown',
        latitude: geoData.geoData?.latitude || 0,
        longitude: geoData.geoData?.longitude || 0,
        elevation: geoData.geoData?.elevation || 0,
        timezone: geoData.geoData?.timezone || 'UTC',
        org: geoData.geoData?.org || 'Unknown',
        postal: geoData.geoData?.postal || '00000',
        geoSource: geoData.geoData?.geoSource || 'unknown'
      },
      geoData2: {
        city: geoData.geoData2?.city || 'Unknown',
        region: geoData.geoData2?.region || 'Unknown',
        country: geoData.geoData2?.country || 'UN',
        country_name: geoData.geoData2?.country_name || 'Unknown',
        latitude: geoData.geoData2?.latitude || 0,
        longitude: geoData.geoData2?.longitude || 0,
        elevation: geoData.geoData2?.elevation || 0,
        timezone: geoData.geoData2?.timezone || 'UTC',
        org: geoData.geoData2?.org || 'Unknown',
        postal: geoData.geoData2?.postal || '00000',
        geoSource: geoData.geoData2?.geoSource || 'unknown'
      },
      
      os: {
        name: result.os.name || 'unknown',
        version: result.os.version || 'unknown',
        platform: navigator.platform
      },
      
      browser: {
        name: result.browser.name || 'unknown',
        version: result.browser.version || 'unknown',
        engine: result.engine.name || 'unknown',
        engineVersion: result.engine.version || 'unknown'
      },
      
      screen: {
        width: screen.width,
        height: screen.height,
        colorDepth: screen.colorDepth,
        pixelRatio: window.devicePixelRatio,
        orientation: screen.orientation?.type || 'unknown'
      },
      
      cpu: {
        architecture: result.cpu.architecture || 'unknown',
        cores: navigator.hardwareConcurrency || null
      },
      
      memory: {
        deviceMemory: (extendedNavigator as any).deviceMemory || null
      },
      
      network: getNetworkInformation(),
      
      language: navigator.language,
      languages: navigator.languages || [navigator.language],
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      timestamp: serverTimestamp(),
      
      mobile: {
        vendor: result.device.vendor || null,
        model: result.device.model || null,
        type: result.device.type || null
      }
    };

    // Store in a separate collection with error handling
    try {
      const deviceCollection = collection(db, 'device_tracking');
      await addDoc(deviceCollection, deviceData);
    } catch (error) {
      console.warn('Error storing device tracking data:', error);
    }
    
    // Store unique devices in a separate collection for analytics with error handling
    try {
      const deviceFingerprintRef = doc(db, 'unique_devices', deviceData.fingerprint);
      const deviceDoc = await getDoc(deviceFingerprintRef);
      
      if (!deviceDoc.exists()) {
        await setDoc(deviceFingerprintRef, {
          fingerprint: deviceData.fingerprint,
          userAgent: deviceData.userAgent,
          ip: deviceData.ip,
          geoData: deviceData.geoData,
          geoData2: deviceData.geoData2,
          firstSeen: serverTimestamp(),
          lastSeen: serverTimestamp(),
          visitCount: 1
        });
      } else {
        await setDoc(deviceFingerprintRef, {
          lastSeen: serverTimestamp(),
          visitCount: increment(1),
          ip: deviceData.ip,
          geoData: deviceData.geoData,
          geoData2: deviceData.geoData2
        }, { merge: true });
      }
    } catch (error) {
      console.warn('Error storing unique device data:', error);
    }
    
  } catch (error) {
    // Silent error handling to not affect main functionality
    console.warn('Error collecting device data:', error);
  }
};