export type ThemeType = 'dark' | 'light' | 'cosmic';

export interface Theme {
  name: string;
  background: string;
  text: string;
  headings: string;
  cardBg: string;
  cardText: string;
  cardHover: string;
  accent: string;
  secondary: string;
}

export const themes: Record<ThemeType, Theme> = {
  dark: {
    name: 'Dark Purple',
    background: 'bg-gradient-to-b from-purple-900 to-black',
    text: 'text-white',
    headings: 'text-purple-300',
    cardBg: 'bg-white/5',
    cardText: 'text-gray-300',
    cardHover: 'hover:bg-white/10',
    accent: '#FF69B4',
    secondary: '#9370DB'
  },
  light: {
    name: 'Light Purple',
    background: 'bg-gradient-to-b from-purple-100 to-white',
    text: 'text-gray-900',
    headings: 'text-purple-600',
    cardBg: 'bg-purple-50',
    cardText: 'text-gray-600',
    cardHover: 'hover:bg-purple-100',
    accent: '#FF1493',
    secondary: '#8A2BE2'
  },
  cosmic: {
    name: 'Cosmic',
    background: 'bg-gradient-to-br from-indigo-900 via-purple-900 to-pink-900',
    text: 'text-purple-50',
    headings: 'text-pink-300',
    cardBg: 'bg-indigo-900/30',
    cardText: 'text-purple-200',
    cardHover: 'hover:bg-indigo-800/40',
    accent: '#FF69B4',
    secondary: '#9370DB'
  },
}; 