import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackVisit } from '../services/visitorManager';

export const useVisitorTracking = () => {
  const location = useLocation();

  useEffect(() => {
    // Track visitor on initial load and route changes
    trackVisit().catch((error: Error) => {
      console.error('Failed to track visitor:', error);
    });
  }, [location.pathname]); // Re-run when the path changes
}; 