import { useState, useEffect } from 'react';

export const useCornPageCheck = () => {
  const [showCornPage, setShowCornPage] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const checkCornPage = () => {
      try {
        // Convert to lowercase and handle different string formats
        const envValue = String(import.meta.env.VITE_ENABLE_CORNPAGE_SURPRISE).toLowerCase();
        const shouldShowCornPage = envValue === 'true' || envValue === '1' || envValue === 'yes';
        setShowCornPage(shouldShowCornPage);
      } catch (error) {
        console.error('Error checking corn page flag:', error);
        setShowCornPage(false);
      } finally {
        setIsLoading(false);
      }
    };

    checkCornPage();
  }, []);

  return { showCornPage, isLoading };
}; 