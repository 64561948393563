export interface IpGeoData {
  ip: string;
  geoData: {
    city: string;
    region: string;
    country: string;
    country_name: string;
    latitude: number;
    longitude: number;
    elevation: number;
    timezone: string;
    org: string;
    postal: string;
    geoSource: string;
  };
  geoData2: {
    city: string;
    region: string;
    country: string;
    country_name: string;
    latitude: number;
    longitude: number;
    elevation: number;
    timezone: string;
    org: string;
    postal: string;
    geoSource: string;
  };
  ipSource: string;
}

const IPAPI_ENDPOINTS = [
  'https://api.ipify.org?format=json',
  'https://api64.ipify.org?format=json',
  'https://ipapi.co/json',
  'https://api.db-ip.com/v2/free/self',
  'https://ipwho.is',
  'https://ip.nf/me.json'
];

const DEFAULT_GEO_DATA: IpGeoData = {
  ip: 'unknown',
  geoData: {
    city: 'Unknown',
    region: 'Unknown',
    country: 'UN',
    country_name: 'Unknown',
    latitude: 0,
    longitude: 0,
    elevation: 0,
    timezone: 'UTC',
    org: 'Unknown',
    postal: '00000',
    geoSource: 'default'
  },
  geoData2: {
    city: 'Unknown',
    region: 'Unknown',
    country: 'UN',
    country_name: 'Unknown',
    latitude: 0,
    longitude: 0,
    elevation: 0,
    timezone: 'UTC',
    org: 'Unknown',
    postal: '00000',
    geoSource: 'default'
  },
  ipSource: 'default'
};

// Function to get elevation data from coordinates
async function getElevation(latitude: number, longitude: number): Promise<number> {
  try {
    const response = await fetch(
      `https://api.open-elevation.com/api/v1/lookup?locations=${latitude},${longitude}`,
      {
        headers: { 'Accept': 'application/json' },
        mode: 'cors',
        credentials: 'omit'
      }
    );

    if (response.ok) {
      const data = await response.json();
      if (data.results && data.results[0]) {
        return data.results[0].elevation;
      }
    }
    return 0;
  } catch (error) {
    console.warn('Failed to get elevation data:', error);
    return 0;
  }
}

// Function to get IP-API data (more precise geolocation)
async function getIpApiData(ip: string): Promise<any> {
  try {
    const response = await fetch(`https://ipapi.co/${ip}/json/`, {
      headers: {
        'Accept': 'application/json',
        'User-Agent': 'Jadyn Rylee Website (https://jadyngiles.netlify.app)'
      },
      mode: 'cors',
      credentials: 'omit'
    });

    if (response.ok) {
      const data = await response.json();
      const elevation = await getElevation(data.latitude || 0, data.longitude || 0);
      return {
        city: data.city || 'Unknown',
        region: data.region || 'Unknown',
        country: data.country || 'UN',
        country_name: data.country_name || 'Unknown',
        latitude: data.latitude || 0,
        longitude: data.longitude || 0,
        elevation,
        timezone: data.timezone || 'UTC',
        org: data.org || 'Unknown',
        postal: data.postal || '00000',
        geoSource: 'ipapi.co'
      };
    }
  } catch (error) {
    console.warn('Failed to get IP-API data:', error);
  }
  return null;
}

export async function getIpGeoLocation(): Promise<IpGeoData> {
  // Try multiple IP API endpoints with CORS handling
  for (const endpoint of IPAPI_ENDPOINTS) {
    try {
      const response = await fetch(endpoint, {
        headers: {
          'Accept': 'application/json'
        },
        mode: 'cors',
        credentials: 'omit'
      });

      if (!response.ok) {
        console.warn(`Failed to fetch from ${endpoint}:`, response.statusText);
        continue;
      }

      const data = await response.json();
      let geoData2 = null;
      
      // Try to get IP-API data as second source
      if (data.ip) {
        geoData2 = await getIpApiData(data.ip);
      }
      
      // Handle different API response formats
      if (endpoint.includes('ipify.org')) {
        // For ipify, we only get IP, try to get more info from ipwho.is
        try {
          const detailsResponse = await fetch(`https://ipwho.is/${data.ip}`, {
            headers: { 'Accept': 'application/json' },
            mode: 'cors',
            credentials: 'omit'
          });
          
          if (detailsResponse.ok) {
            const details = await detailsResponse.json();
            const elevation = await getElevation(details.latitude || 0, details.longitude || 0);
            return {
              ip: data.ip,
              geoData: {
                city: details.city || 'Unknown',
                region: details.region || 'Unknown',
                country: details.country_code || 'UN',
                country_name: details.country || 'Unknown',
                latitude: details.latitude || 0,
                longitude: details.longitude || 0,
                elevation,
                timezone: details.timezone?.id || 'UTC',
                org: details.connection?.org || 'Unknown',
                postal: details.postal || '00000',
                geoSource: 'ipwho.is'
              },
              geoData2: geoData2 || DEFAULT_GEO_DATA.geoData2,
              ipSource: endpoint.includes('api64') ? 'ipify.org-v6' : 'ipify.org-v4'
            };
          }
        } catch (error) {
          console.warn('Failed to get detailed IP info:', error);
        }
        
        return {
          ip: data.ip,
          geoData: {
            ...DEFAULT_GEO_DATA.geoData,
            geoSource: 'ipify-only'
          },
          geoData2: geoData2 || DEFAULT_GEO_DATA.geoData2,
          ipSource: endpoint.includes('api64') ? 'ipify.org-v6' : 'ipify.org-v4'
        };
      } else if (endpoint.includes('ipapi.co')) {
        const elevation = await getElevation(data.latitude || 0, data.longitude || 0);
        return {
          ip: data.ip,
          geoData: {
            city: data.city || 'Unknown',
            region: data.region || 'Unknown',
            country: data.country || 'UN',
            country_name: data.country_name || 'Unknown',
            latitude: data.latitude || 0,
            longitude: data.longitude || 0,
            elevation,
            timezone: data.timezone || 'UTC',
            org: data.org || 'Unknown',
            postal: data.postal || '00000',
            geoSource: 'ipapi.co'
          },
          geoData2: geoData2 || DEFAULT_GEO_DATA.geoData2,
          ipSource: 'ipapi.co'
        };
      } else if (endpoint.includes('db-ip.com')) {
        return {
          ip: data.ipAddress,
          geoData: {
            city: data.city || 'Unknown',
            region: data.region || 'Unknown',
            country: data.countryCode || 'UN',
            country_name: data.countryName || 'Unknown',
            latitude: 0,
            longitude: 0,
            elevation: 0,
            timezone: data.timeZone || 'UTC',
            org: 'Unknown',
            postal: '00000',
            geoSource: 'db-ip.com'
          },
          geoData2: geoData2 || DEFAULT_GEO_DATA.geoData2,
          ipSource: 'db-ip.com'
        };
      } else if (endpoint.includes('ipwho.is')) {
        const elevation = await getElevation(data.latitude || 0, data.longitude || 0);
        return {
          ip: data.ip,
          geoData: {
            city: data.city || 'Unknown',
            region: data.region || 'Unknown',
            country: data.country_code || 'UN',
            country_name: data.country || 'Unknown',
            latitude: data.latitude || 0,
            longitude: data.longitude || 0,
            elevation,
            timezone: data.timezone?.id || 'UTC',
            org: data.connection?.org || 'Unknown',
            postal: data.postal || '00000',
            geoSource: 'ipwho.is'
          },
          geoData2: geoData2 || DEFAULT_GEO_DATA.geoData2,
          ipSource: 'ipwho.is'
        };
      } else if (endpoint.includes('ip.nf')) {
        const ipInfo = data.ip;
        const elevation = await getElevation(parseFloat(ipInfo.latitude) || 0, parseFloat(ipInfo.longitude) || 0);
        return {
          ip: ipInfo.ip,
          geoData: {
            city: ipInfo.city || 'Unknown',
            region: ipInfo.region || 'Unknown',
            country: ipInfo.country_code || 'UN',
            country_name: ipInfo.country || 'Unknown',
            latitude: parseFloat(ipInfo.latitude) || 0,
            longitude: parseFloat(ipInfo.longitude) || 0,
            elevation,
            timezone: ipInfo.time_zone || 'UTC',
            org: ipInfo.asn || 'Unknown',
            postal: ipInfo.postal_code || '00000',
            geoSource: 'ip.nf'
          },
          geoData2: geoData2 || DEFAULT_GEO_DATA.geoData2,
          ipSource: 'ip.nf'
        };
      }
    } catch (error) {
      console.warn(`Error fetching from ${endpoint}:`, error);
    }
  }

  // If all APIs fail, try one last time with ipify
  try {
    const response = await fetch('https://api.ipify.org?format=json', {
      mode: 'cors',
      credentials: 'omit'
    });
    if (response.ok) {
      const data = await response.json();
      return {
        ip: data.ip,
        geoData: {
          ...DEFAULT_GEO_DATA.geoData,
          geoSource: 'ipify-fallback'
        },
        geoData2: DEFAULT_GEO_DATA.geoData2,
        ipSource: 'ipify.org-v4'
      };
    }
  } catch (error) {
    console.warn('Failed to get IP from fallback service:', error);
  }

  // Last resort: return default data
  return DEFAULT_GEO_DATA;
} 