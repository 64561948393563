import { lazy } from 'react';

// Lazy load the BirthdayPage component
export const LazyBirthdayPage = lazy(() => import('../pages/BirthdayPage/BirthdayPage'));

// Lazy load the CornPage component
export const LazyCornPage = lazy(() => import('../pages/CornPage/CornPage'));

// Lazy load the AdminDashboard component
export const LazyAdminDashboard = lazy(() => 
  import('../pages/admin/Dashboard').then(module => ({
    default: module.AdminDashboard
  }))
);

// Lazy load the OfflinePage component
export const LazyOfflinePage = lazy(() => 
  import('./OfflinePage').then(module => ({
    default: module.OfflinePage
  }))
);

// Lazy load the ComingSoon section
export const LazyComingSoonSection = lazy(() => import('./ComingSoonSection'));

// Lazy load the FeaturedVideo component
export const LazyFeaturedVideo = lazy(() => import('./FeaturedVideo'));

// Lazy load the SocialLinks component
export const LazySocialLinks = lazy(() => import('./SocialLinks')); 