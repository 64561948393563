import React from 'react';
import { type Theme } from '../../config/themes';

interface HeaderProps {
  title?: string;
  theme: Theme;
}

export const Header: React.FC<HeaderProps> = ({ title = 'Jadyn Rylee', theme }) => {
  return (
    <header className={`${theme.cardBg} backdrop-blur-sm transition-colors duration-300 mb-12`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="py-12">
          <h1 className={`text-4xl sm:text-5xl md:text-6xl font-bold text-center text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-300 leading-loose tracking-wide py-4`}>
            {title}
          </h1>
        </div>
      </div>
    </header>
  );
}; 