import { isToday, parse, differenceInYears } from 'date-fns';

export interface BirthdayConfig {
  isEnabled: boolean;
  birthdayDate: string;
  birthYear: number;
  messages: string[];
}

// Get environment variables with type safety
const getBirthdayConfig = (): BirthdayConfig => {
  return {
    isEnabled: import.meta.env.VITE_ENABLE_BIRTHDAY_SURPRISE === 'true',
    birthdayDate: import.meta.env.VITE_ARTIST_BIRTHDAY || '',
    birthYear: parseInt(import.meta.env.VITE_ARTIST_BIRTH_YEAR || '0', 10),
    messages: [
      "🎉 Happy Birthday! 🎉",
      "May your day be filled with joy and music! 🎵",
      "Your voice continues to inspire! ⭐",
      "Here's to another amazing year! 🌟"
    ]
  };
};

// Calculate current age
export const getCurrentAge = (): number => {
  const { birthYear, birthdayDate } = getBirthdayConfig();
  if (!birthYear || !birthdayDate) return 0;
  
  const [month, day] = birthdayDate.split('-').map(num => parseInt(num, 10));
  const birthDate = new Date(birthYear, month - 1, day);
  return differenceInYears(new Date(), birthDate);
};

// Check if today is the birthday
export const isBirthdayToday = (): boolean => {
  const { isEnabled, birthdayDate } = getBirthdayConfig();
  
  if (!isEnabled || !birthdayDate) {
    return false;
  }

  try {
    const birthday = parse(birthdayDate, 'MM-dd', new Date());
    return isToday(birthday);
  } catch (error) {
    console.error('Error parsing birthday date:', error);
    return false;
  }
};

export const getBirthdayMessages = (): string[] => {
  return getBirthdayConfig().messages;
};

export default getBirthdayConfig; 