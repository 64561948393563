import React, { useEffect, Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useVisitCounter } from './hooks/useVisitCounter';
import { useFeatures } from './hooks/useFeatures';
import { useTheme } from './hooks/useTheme';
import { useOnlineStatus } from './hooks/useOnlineStatus';
import { Header } from './components/Layout/Header';
import { Container } from './components/Layout/Container';
import { ThemeSwitcher } from './components/UI/ThemeSwitcher';
import { useVisitorTracking } from './hooks/useVisitorTracking';
import { AdminLayout } from './components/Layout/AdminLayout';
import { trackVisit } from './services/visitorManager';
import { collectDeviceData } from './services/deviceTracking';
import { useBirthdayCheck } from './hooks/useBirthdayCheck';
import { useCornPageCheck } from './hooks/useCornPageCheck';
import { 
  LazyBirthdayPage, 
  LazyCornPage,
  LazyAdminDashboard, 
  LazyOfflinePage, 
  LazyComingSoonSection,
  LazyFeaturedVideo,
  LazySocialLinks 
} from './components/LazyComponents';

export const ComingSoonCard: React.FC<{ title: string; description: string; theme: any }> = ({ title, description, theme }) => (
  <div className="relative group">
    <div className={`${theme.cardBg} backdrop-blur-sm rounded-lg p-6 transform group-hover:scale-105 transition-all duration-300 ${theme.cardHover}`}>
      <h3 className={`text-xl font-semibold mb-3 ${theme.headings}`}>
        {title}
      </h3>
      <p className={theme.cardText}>
        {description}
      </p>
    </div>
    <div className="absolute inset-0 bg-black/75 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
      <span className="text-white text-xl font-bold">Coming Soon?</span>
    </div>
  </div>
);

export const FeaturedVideo: React.FC<{ theme: any }> = ({ theme }) => {
  const videoId = import.meta.env.VITE_FEATURED_VIDEO_ID;
  const videoTitle = import.meta.env.VITE_FEATURED_VIDEO_TITLE;
  
  if (!videoId) return null;

  return (
    <div className={`${theme.cardBg} backdrop-blur-sm rounded-lg p-6 mb-16 transform transition-all duration-300 hover:scale-[1.02]`}>
      <h3 className={`text-2xl font-semibold mb-6 ${theme.headings}`}>
        Latest Video
      </h3>
      <div className="relative pb-[56.25%] h-0 overflow-hidden rounded-lg shadow-xl">
        <iframe
          className="absolute top-0 left-0 w-full h-full"
          src={`https://www.youtube.com/embed/${videoId}`}
          title={videoTitle}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  );
};

export const SocialLinks: React.FC<{ theme: any }> = ({ theme }) => {
  const socials = [
    {
      name: 'Facebook',
      url: import.meta.env.VITE_SOCIAL_FACEBOOK,
      icon: (
        <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
          <path d="M18.77 7.46H14.5v-1.9c0-.9.6-1.1 1-1.1h3V.5h-4.33C10.24.5 9.5 3.44 9.5 5.32v2.15h-3v4h3v12h5v-12h3.85l.42-4z"/>
        </svg>
      )
    },
    {
      name: 'Spotify',
      url: import.meta.env.VITE_SOCIAL_SPOTIFY,
      icon: (
        <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
          <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.359-.66.48-1.021.24-2.82-1.74-6.36-2.101-10.561-1.141-.418.122-.779-.179-.899-.539-.12-.421.18-.78.54-.9 4.56-1.021 8.52-.6 11.64 1.32.42.18.479.659.301 1.02zm1.44-3.3c-.301.42-.841.6-1.262.3-3.239-1.98-8.159-2.58-11.939-1.38-.479.12-1.02-.12-1.14-.6-.12-.48.12-1.021.6-1.141C9.6 9.9 15 10.561 18.72 12.84c.361.181.54.78.241 1.2zm.12-3.36C15.24 8.4 8.82 8.16 5.16 9.301c-.6.179-1.2-.181-1.38-.721-.18-.601.18-1.2.72-1.381 4.26-1.26 11.28-1.02 15.721 1.621.539.3.719 1.02.419 1.56-.299.421-1.02.599-1.559.3z"/>
        </svg>
      )
    },
    {
      name: 'YouTube',
      url: import.meta.env.VITE_SOCIAL_YOUTUBE,
      icon: (
        <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
          <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z"/>
        </svg>
      )
    },
    {
      name: 'TikTok',
      url: import.meta.env.VITE_SOCIAL_TIKTOK,
      icon: (
        <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
          <path d="M12.53.02C13.84 0 15.14.01 16.44 0c.08 1.53.63 3.09 1.75 4.17 1.12 1.11 2.7 1.62 4.24 1.79v4.03c-1.44-.05-2.89-.35-4.2-.97-.57-.26-1.1-.59-1.62-.93-.01 2.92.01 5.84-.02 8.75-.08 1.4-.54 2.79-1.35 3.94-1.31 1.92-3.58 3.17-5.91 3.21-1.43.08-2.86-.31-4.08-1.03-2.02-1.19-3.44-3.37-3.65-5.71-.02-.5-.03-1-.01-1.49.18-1.9 1.12-3.72 2.58-4.96 1.66-1.44 3.98-2.13 6.15-1.72.02 1.48-.04 2.96-.04 4.44-.99-.32-2.15-.23-3.02.37-.63.41-1.11 1.04-1.36 1.75-.21.51-.15 1.07-.14 1.61.24 1.64 1.82 3.02 3.5 2.87 1.12-.01 2.19-.66 2.77-1.61.19-.33.4-.67.41-1.06.1-1.79.06-3.57.07-5.36.01-4.03-.01-8.05.02-12.07z"/>
        </svg>
      )
    },
    {
      name: 'Instagram',
      url: import.meta.env.VITE_SOCIAL_INSTAGRAM,
      icon: (
        <svg className="w-8 h-8" fill="currentColor" viewBox="0 0 24 24">
          <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
        </svg>
      )
    }
  ];

  return (
    <div className={`${theme.cardBg} backdrop-blur-sm rounded-lg p-6 mb-16`}>
      <h3 className={`text-2xl font-semibold mb-6 ${theme.headings}`}>
        Connect With Me
      </h3>
      <div className="flex flex-wrap justify-center gap-8">
        {socials.map(social => (
          <a
            key={social.name}
            href={social.url}
            target="_blank"
            rel="noopener noreferrer"
            className={`transform transition-all duration-300 hover:scale-110 hover:text-purple-400 ${theme.text}`}
            aria-label={`Follow on ${social.name}`}
          >
            {social.icon}
          </a>
        ))}
      </div>
    </div>
  );
};

// Loading fallback component
const LoadingFallback = () => (
  <div className="min-h-screen flex items-center justify-center">
    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-purple-500"></div>
  </div>
);

const MainContent = () => {
  const { totalVisits, uniqueVisitors, countryStats, loading, error } = useVisitCounter();
  const features = useFeatures();
  const { theme, currentThemeName, cycleTheme } = useTheme('dark');
  useVisitorTracking();

  return (
    <div className={`min-h-screen ${theme.background} ${theme.text} transition-colors duration-300`}>
      <Header title="Jadyn Rylee" theme={theme} />
      
      <main className="py-6">
        <Container>
          <div className="text-center">
            <h2 className={`text-3xl sm:text-4xl font-bold mb-16 leading-relaxed ${theme.headings}`}>
              Welcome to my official page!
            </h2>
            
            {/* Featured Video Section */}
            {features.isVideoSectionEnabled && (
              <Suspense fallback={<LoadingFallback />}>
                <LazyFeaturedVideo theme={theme} />
              </Suspense>
            )}
            
            {/* Social Links Section */}
            {features.isSocialLinksEnabled && (
              <Suspense fallback={<LoadingFallback />}>
                <LazySocialLinks theme={theme} />
              </Suspense>
            )}
            
            {/* Coming Soon Section */}
            <Suspense fallback={<LoadingFallback />}>
              <LazyComingSoonSection theme={theme} />
            </Suspense>

            {/* Visit Counter Section at Bottom */}
            {features.isVisitCounterEnabled && (
              <div className={`${theme.cardBg} backdrop-blur-sm rounded-lg p-8 shadow-xl transition-colors duration-300`}>
                <div className="space-y-6">
                  {loading ? (
                    <div className="animate-pulse">
                      <div className="h-8 bg-purple-200/20 rounded w-48 mx-auto"></div>
                    </div>
                  ) : error ? (
                    <p className="text-red-400">Error loading visit count</p>
                  ) : (
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                      <div className="flex flex-col items-center space-y-2">
                        <p className={`text-xl ${theme.headings}`}>
                          Total Visits
                        </p>
                        <p className={`text-5xl font-bold ${theme.headings}`}>
                          {totalVisits.toLocaleString()}
                        </p>
                      </div>
                      <div className="flex flex-col items-center space-y-2">
                        <p className={`text-xl ${theme.headings}`}>
                          Unique Visitors
                        </p>
                        <p className={`text-5xl font-bold ${theme.headings}`}>
                          {uniqueVisitors.toLocaleString()}
                        </p>
                      </div>
                      <div className="md:col-span-2 mt-6">
                        {features.isTopCountriesEnabled && (
                          <>
                            <p className={`text-xl ${theme.headings} mb-4`}>
                              Top Visiting Countries
                            </p>
                            <div className="flex flex-wrap justify-center gap-4">
                              {Object.entries(countryStats).map(([country, count]) => (
                                <div
                                  key={country}
                                  className={`${theme.cardBg} rounded-full px-4 py-2 text-sm`}
                                >
                                  {country}: {count.toLocaleString()}
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </Container>
      </main>

      <ThemeSwitcher currentTheme={currentThemeName} onSwitch={cycleTheme} />
    </div>
  );
};

const App: React.FC = () => {
  const { isBirthday, isLoading: isBirthdayLoading } = useBirthdayCheck();
  const { showCornPage, isLoading: isCornPageLoading } = useCornPageCheck();
  const isOnline = useOnlineStatus();

  useEffect(() => {
    // Track visitor and device data when the app loads
    if (isOnline) {
      Promise.all([
        trackVisit().catch((error: Error) => {
          console.warn('Failed to track visitor:', error);
        }),
        collectDeviceData().catch(error => {
          console.warn('Failed to collect device data:', error);
        })
      ]);
    }
  }, [isOnline]);

  if (!isOnline) {
    return (
      <Suspense fallback={<LoadingFallback />}>
        <LazyOfflinePage />
      </Suspense>
    );
  }

  // Show corn page if enabled
  if (showCornPage && !isCornPageLoading) {
    return (
      <Suspense fallback={<LoadingFallback />}>
        <LazyCornPage />
      </Suspense>
    );
  }

  // Show birthday page if it's the artist's birthday
  if (isBirthday && !isBirthdayLoading) {
    return (
      <Suspense fallback={<LoadingFallback />}>
        <LazyBirthdayPage />
      </Suspense>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/admin/*" element={
          <AdminLayout>
            <Suspense fallback={<LoadingFallback />}>
              <LazyAdminDashboard />
            </Suspense>
          </AdminLayout>
        } />
        <Route path="/*" element={<MainContent />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
